import React, { useState, useEffect } from 'react';
import { Button, TextField, Paper, Typography, Box, CircularProgress } from '@mui/material';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'wss://hexa.davedata.it:443';
const socket = io(websocketUrl, {secure: true});  // Collegamento a Socket.io

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // Stato di caricamento

  // Genera un nuovo userId all'avvio della pagina (o ad ogni refresh)
  useEffect(() => {
    const newUserId = uuidv4();
    setUserId(newUserId);
  }, []);

  useEffect(() => {
    // Ascolta i messaggi dal server
    socket.on('receiveMessage', (data) => {
      const assistantMessage = { text: data.message, sender: 'assistant' };
      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      setIsLoading(false);  // Nascondi lo spinner quando arriva la risposta
    });

    socket.on('error', (errorMessage) => {
      console.error('Errore ricevuto:', errorMessage);
      setIsLoading(false);  // Nascondi lo spinner in caso di errore
    });

    return () => {
      socket.off('receiveMessage');
      socket.off('error');
    };
  }, []);

  const sendMessage = () => {
    if (input.trim()) {
      const userMessage = { text: input, sender: 'user' };
      setMessages([...messages, userMessage]);
      setIsLoading(true);  // Mostra lo spinner quando l'utente invia il messaggio

      // Invia il messaggio e il userId al server via socket
      socket.emit('sendMessage', {
        message: input,
        userId: userId  // Passiamo l'ID utente univoco
      });

      setInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const clearMessages = () => {
    setMessages([]);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: '8px' }}>
        <Typography variant="h4" gutterBottom>Hexa-AI by Interactia</Typography>
        <Box sx={{ height: 'calc(100vh - 212px)', overflowY: 'auto', marginBottom: 2 }}>
          {messages.map((msg, index) => (
		    <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',  // Allinea a destra o sinistra
                marginBottom: 1
              }}
            >
              <Typography
                sx={{
                  backgroundColor: msg.sender === 'user' ? '#d1f7c4' : '#f1f1f1',
                  padding: 1,
                  borderRadius: '5px',
                  maxWidth: '75%'
                }}
              >
                {msg.text}
              </Typography>
			</Box>
          ))}

          {/* Spinner e messaggio "Sto pensando..." */}
          {isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <CircularProgress size={24} />
              <Typography variant="body1" sx={{ ml: 2 }}>Sto pensando...</Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Scrivi il tuo messaggio..."
          />
          <Button variant="contained" color="primary" onClick={sendMessage} sx={{ marginLeft: 2 }}>
            Invia
          </Button>
        </Box>
        <Button variant="outlined" color="secondary" onClick={clearMessages} fullWidth>
          Cancella tutti i messaggi
        </Button>
      </Paper>
    </Box>
  );
}

export default App;
